import { z } from "zod";

// Comes from cropvisor-service
import { PaginatedResponse } from "./pagination.js";
import {
  getV2OperationPathOperationIdFieldsResponse,
  getV2OperationsSummaryResponse,
} from "../schemas/index.js";

export type FieldStatus = "SUPPORTED" | "CROP_NOT_SUPPORTED" | "INCOMPLETE";

export type FieldData = {
  coverCrop: number | undefined;
  irrigation: string | undefined;
  lbsNitrogen: number | undefined;
  previousCrop: string | undefined;
  tillage: string | undefined;
};

export type OperationsSummaryResponse = z.infer<
  typeof getV2OperationsSummaryResponse
>;

export type FieldsByOperationResponse = z.infer<
  typeof getV2OperationPathOperationIdFieldsResponse
>;

export enum SprayTimingState {
  NO_DATA_V1 = "NO DATA",
  NO_DATA = "NO_DATA",
  HIGH_PRIORITY = "SPRAY_NOW",
  HIGH_PRIORITY_V1 = "LT7",
  HIGH_PRIORITY_V2 = "0-3 days",
  MEDIUM_PRIORITY = "SPRAY_SOON",
  MEDIUM_PRIORITY_V1 = "LT14",
  NO_ACTION_NEEDED = "NO_ACTION",
  CALCULATING = "CALCULATING",
  SKIPPED = "SKIPPED",
  OUT_OF_SEASON = "OUT_OF_SEASON",
  MONITORING = "MONITORING",
  NO_RECOMMENDATION = "NO_RECOMMENDATION",
  NO_RECOMMENDATION_V2 = "No recommendation",
  INCOMPLETE = "INCOMPLETE",
  LATE_SPRAY = "LATE_SPRAY",
  UPCOMING_SPRAY = "UPCOMING",
  OVERDUE_SPRAY_SOFT = "OVERDUE_SOFT",
  OVERDUE_SPRAY_HARD = "OVERDUE_HARD",
  OVERDUE_SPRAY_V2 = "Already occured",
  SPRAYED = "SPRAYED",
  CROP_NOT_SUPPORTED = "CROP_NOT_SUPPORTED",
  WINDOW_PASSED = "WINDOW_PASSED",
}

// JUST (ALMOST) A COPY OF getV2OperationPathOperationIdFieldsResponse.
// This is just a temporary type until we have the inferred type from the real endpoint
const getV2ContextFieldsResponse = z.object({
  data: z.array(
    z.object({
      operationId: z.string(),
      operationName: z.string(),
      fieldId: z.string(),
      fieldName: z.string(),
      farmId: z.string(),
      farmName: z.string(),
      fieldAcres: z.number(),
      productName: z.string(),
      recommendationStatus: z.string(),
      activities: z.array(
        z.object({
          activityId: z.string(),
          cropAssignmentId: z.string(),
          plannedDate: z.string(),
          completedDate: z.string(),
          plantingRate: z.number(),
          displayName: z.string(),
          units: z.string(),
          noOfUnits: z.number(),
          crop: z.string(),
          cropId: z.string(),
          earliestPlannedDate: z.string(),
          earliestCompletedDate: z.string(),
          activitySummaries: z.array(
            z.object({
              activityId: z.string(),
              plantingRate: z.number(),
              displayName: z.string(),
              units: z.string(),
              noOfUnits: z.number(),
              crop: z.string(),
              error: z.string(),
              cropAssignmentId: z.string(),
              plannedDate: z.string(),
              completedDate: z.string(),
              cropId: z.string(),
              productId: z.string(),
              activitySummaries: z
                .array(
                  z.object({
                    plantingRate: z.number(),
                    displayName: z.string(),
                    units: z.string(),
                    noOfUnits: z.string(),
                    crop: z.string(),
                    error: z.string(),
                  })
                )
                .optional(),
            })
          ),
        })
      ),
      skipped: z.boolean(),
      sprayed: z.boolean(),
      sprayedDate: z.string(),
      isSupported: z.boolean(),
      state: z.string(),
      status: z.nativeEnum(SprayTimingState),
      incompleteDefinition: z.string(),
      bestActivity: z.object({
        activityId: z.string(),
        productId: z.string(),
        displayName: z.string(),
        date: z.string().nullable(),
      }),
      shapeEncoded: z.string(),
    })
  ),
  metadata: z.object({
    count: z.number(),
    totalCount: z.number(),
    totalPages: z.number(),
  }),
  links: z.object({
    self: z.string(),
    first: z.string(),
    prev: z.string(),
    next: z.string(),
    last: z.string(),
  }),
});

export type FieldsSummaryResponse = z.infer<typeof getV2ContextFieldsResponse>;

export const markAsSprayedResponseSchema = z.object({
  data: z.array(
    z.object({
      fieldId: z.string(),
      operationId: z.string(),
      retrieved: z.string(),
      modelStartDate: z.string(),
      modelEndDate: z.string(),
      sprayWindow: z.string(),
      activityId: z.string(),
      plannedDate: z.string(),
      completedDate: z.string(),
      rawModelOutput: z.string(),
      skipped: z.boolean(),
      sprayed: z.boolean(),
      sprayedDate: z.string(),
      modelProductId: z.string(),
      fieldData: z.object({
        previousCrop: z.string(),
        tillage: z.string(),
        coverCrop: z.boolean(),
        irrigation: z.string(),
        lbsNitrogen: z.number(),
      }),
      vNextRawModelOutput: z.string(),
    })
  ),
});

export type MarkAsSprayedResponse = z.infer<typeof markAsSprayedResponseSchema>;

export type CondensedMarkAsSprayedResponse = {
  [operationId: string]: {
    fieldId: string;
    sprayed: boolean;
    sprayedDate: string;
  }[];
};

export type MarkAsSprayedRequestBody = {
  operations: Record<string, string[]>,
  sprayed: boolean,
  sprayedDate?: string,
};

export type OperationsFieldsResponse = z.infer<
  typeof getV2ContextFieldsResponse
>;

export type Recommendation = {
  fieldData: FieldData;
  message: string | undefined;
  previewMessage: string | undefined;
  skipped: boolean | undefined;
  sprayed: boolean;
  sprayedDate: string | undefined;
};

export type ActivitySummary = {
  crop: string | undefined;
  displayName: string | undefined;
  error: string | undefined;
  noOfUnits: number | undefined;
  plantingRate: number | undefined;
  units: string | undefined;
};

export type Activity = {
  activityId: string | undefined;
  activitySummaries: ActivitySummary[];
  completedDate: string | undefined;
  crop: string | undefined;
  cropId: string | undefined;
  cropAssignmentId: string | undefined;
  displayName: string | undefined;
  noOfUnits: number | undefined;
  plannedDate: string | undefined;
  plantingRate: number | undefined;
  units: string | undefined;
};

export type CropVisorBestActivity = {
  activityId: string;
  productId: string;
  date: string | null;
};

export type FieldSummary = {
  activities: Activity[];
  area: number;
  assignedCrops: string[];
  cropInformation: string;
  errors: string[];
  farmId: string | undefined;
  farmName: string | undefined;
  fieldId: string;
  fieldName: string;
  latitude: number;
  longitude: number;
  plantDate: string | undefined;
  productName: string | undefined;
  recommendation: Recommendation;
  shapeEncoded: string;
  status: FieldStatus;
  thumbnailSvg: string;
  bestActivity: CropVisorBestActivity | undefined;
};

export type OperationSummary = {
  businessCycle: string | undefined;
  errors: string[];
  fields: FieldSummary[];
  operationId: string;
  totalFieldArea: number;
  totalFieldCount: number;
};

export type OperationSummariesResponse = PaginatedResponse<OperationSummary>;

const TillingOptionsSchema = z
  .enum(["CONV", "CONS", "No-Till", ""])
  .optional()
  .default("");
export type TillingOptions = z.infer<typeof TillingOptionsSchema>;

const IrrigationEnumSchema = z
  .enum(["Full", "Limited", "None", ""])
  .optional()
  .default("");
export type IrrigationOptions = z.infer<typeof IrrigationEnumSchema>;

const CropOptionsSchema = z
  .enum(["Corn", "Soy", "Other", ""])
  .optional()
  .default("");
export type CropOptions = z.infer<typeof CropOptionsSchema>;

export const additionalFieldDataSchema = z.object({
  irrigation: IrrigationEnumSchema,
  tillage: TillingOptionsSchema,
  coverCrop: z.union([z.boolean(), z.number()]).default(false),
  lbsNitrogen: z.string().default("0"),
  previousCrop: CropOptionsSchema,
});

export type AdditionalFieldData = z.infer<typeof additionalFieldDataSchema>;

const additionalFieldDataRequestBodySchema = z.object({
  fieldId: z.string(),
  fieldData: additionalFieldDataSchema,
});

export type AdditionalFieldDataRequestBody = z.infer<
  typeof additionalFieldDataRequestBodySchema
>;

export const additionalFieldDataApiResponseSchema = z.object({
  field_id: z.string(),
  operation_id: z.string(),
  planned_date: z.string(),
  spray_window: z.nativeEnum(SprayTimingState),
  model_end_date: z.string(),
  completed_date: z.string(),
  id: z.string(),
  field_data: additionalFieldDataSchema,
  model_start_date: z.string(),
  activity_id: z.string(),
  retrieved: z.string(),
  skipped: z.string(),
});

export type AdditionalFieldDataApiResponse = z.infer<
  typeof additionalFieldDataApiResponseSchema
>;

export const saveFieldDetailsArgsSchema = z.object({
  operationId: z.string(),
  fieldDetailsBody: additionalFieldDataRequestBodySchema,
});

export type SaveFieldDetailsArgs = z.infer<typeof saveFieldDetailsArgsSchema>;

export type SkipCropProtectionRequestItem = {
  fieldId: string;
  operationId: string;
  skipped?: boolean;
  isRegistered: boolean;
};
export type SkipCropProtectionRequestBody = SkipCropProtectionRequestItem[];

export type FieldRegistrationResponse = {
  id: string;
  field_id: string;
  operation_id: string;
  spray_window: string;
  retrieved: string;
  model_start_date: string;
  model_end_date: string;
  activity_id: string;
  planned_date: string;
  completed_date: string;
  skipped: string;
  field_data: FieldData;
};

export type Acl = {
  d: string[];
  a: string[];
};
